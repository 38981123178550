import { FormEvent, ReactNode } from 'react';
import { Button } from '../common/Button';
import { Modal } from './Modal';

const CONFIRM_MODAL_PRESETS = {
  delete: {
    title: 'Delete?',
    confirmText: 'Delete',
  },
  duplicate: {
    title: 'Duplicate?',
    confirmText: 'Duplicate',
  },
  unsaved: {
    title: 'Leave page?',
    content: 'Are you sure you want to leave without saving?\nChanges you made will not be saved.',
    confirmText: 'Save and leave',
    cancelText: 'Leave without saving',
  },
};

export interface ConfirmModalProps {
  preset?: keyof typeof CONFIRM_MODAL_PRESETS;
  open: boolean;
  title?: string;
  content?: ReactNode;
  confirmText?: string;
  cancelText?: string;
  performAction?: () => void;
  toggleModal: (type?: any) => void;
  toggleModalPanel?: (type?: any) => void;
  panelForm?: boolean;
  handleSubmit?: (e: FormEvent<HTMLFormElement>) => void;
}

export function ConfirmModal({
  open,
  preset,
  performAction,
  toggleModal,
  toggleModalPanel,
  handleSubmit,
  panelForm,
  ...props
}: ConfirmModalProps) {
  const presetObject: any = preset ? CONFIRM_MODAL_PRESETS[preset] : {};
  const title = props.title ?? presetObject.title;
  const content = props.content ?? presetObject.content;
  const confirmText = props.confirmText ?? presetObject.confirmText ?? 'OK';
  const cancelText = props.cancelText ?? presetObject.cancelText ?? 'Cancel';

  function handlePanelFormSubmission(e) {
    if (panelForm) handleSubmit?.(e);
    performAction?.();
  }

  return (
    <Modal open={!!open} onClose={toggleModal}>
      <Modal.Head onClose={toggleModal}>{title}</Modal.Head>
      <Modal.Body>
        {content}
        <div className='flex flex-row-reverse gap-2 pt-3'>
          <Button
            className='filled-primary'
            onClick={handlePanelFormSubmission}
            children={confirmText}
          />
          <Button
            type='button'
            className='outlined-primary'
            onClick={() => (toggleModal(), toggleModalPanel?.())}
            children={cancelText}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}
